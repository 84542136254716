import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LocaleService } from '@core/services/locale/locale.service';

@Component({
  selector: 'app-timeline-container',
  templateUrl: './timeline-container.component.html',
  styleUrls: ['./timeline-container.component.scss'],
})
export class TimelineContainerComponent implements OnInit, OnDestroy {
  @ViewChild('firstDate', { static: false }) firstDateElement!: ElementRef;

  @Input() events = [
    { date: new Date('2025-01-01'), icon: 'path/to/icon1.png', newAssignee: null },
    { date: new Date('2025-01-03'), icon: 'path/to/icon2.png' },
    { date: new Date('2025-01-12'), icon: 'path/to/icon3.png' },
    // Add more events as needed
  ];
  // Define the minimum width per event in pixels
  minWidthPerEvent = 110;
  timelineWidth = 100;
  baseFirstItemPosition = 2;
  cachedProgress = 0;
  progressInterval!: any;
  eventCrossedStatus: boolean[] = [];
  nextUpcomingEventDate: Date;

  constructor(public localeService: LocaleService) {}
  ngOnInit(): void {
    this.calculateTimelineWidth();
    this.setProgressInterval();
  }

  calculateTimelineWidth(): void {
    const totalEvents = this.events.length;
    const minWidth = totalEvents * this.minWidthPerEvent;
    const screenWidth = window.innerWidth;
    this.timelineWidth = (Math.max(minWidth, screenWidth) / screenWidth) * 100; // Ensure at least full screen width
  }

  getPosition(date: Date, i: number): string {
    const firstDate = this.events[0].date;
    const lastDate = this.events[this.events.length - 1].date;
    const totalDays = this.getDaysBetweenDates(firstDate, lastDate);
    const daysFromStart = this.getDaysBetweenDates(firstDate, date);
    return `calc(${Math.max((daysFromStart / totalDays) * 100, this.baseFirstItemPosition)}% - ${i === this.events?.length - 1 ? '10px' : '0px'})`;
  }

  getDaysBetweenDates(startDate: Date, endDate: Date): number {
    return Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 60));
  }
  updateProgress() {
    this.cachedProgress = this.getProgress();
  }
  getProgress(): number {
    const firstDate = this.events[0].date;
    const lastDate = this.events[this.events.length - 1].date;
    const today = new Date();

    // Total time span in days (including fractional days)
    const totalDays = this.getTimeDifferenceInDays(firstDate, lastDate);

    // Time elapsed from start to today in days (including fractional days)
    const daysFromStart = Math.min(this.getTimeDifferenceInDays(firstDate, today), totalDays);

    this.eventCrossedStatus = this.events.map((event) => this.isEventCrossed(event.date));
    this.nextUpcomingEventDate = this.getNextUpcomingEventDate();
    return (daysFromStart / totalDays) * 100;
  }
  isEventCrossed(eventDate: Date): boolean {
    const now = new Date();
    return now > eventDate;
  }
  getNextUpcomingEventDate(): Date {
    const now = new Date();
    const nextEvent = this.events.find((event) => event.date > now);
    return nextEvent ? nextEvent.date : null;
  }
  getTimeDifferenceInDays(startDate: Date, endDate: Date): number {
    const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
    return differenceInMilliseconds / (1000 * 60 * 60); // Convert milliseconds to days
  }

  get isRtl() {
    return document?.body?.dir == 'rtl' ? true : false;
  }

  firstDateHalfWidth = 0;
  getFirstEventPosition(): string {
    if (this.firstDateElement) {
      const dateWidth = this.firstDateElement?.nativeElement?.offsetWidth;
      if (dateWidth) this.firstDateHalfWidth = dateWidth / 2;
    }
    return this.firstDateHalfWidth
      ? `${this.firstDateHalfWidth + this.baseFirstItemPosition}px`
      : `${this.baseFirstItemPosition}px`;
  }
  setProgressInterval() {
    this.updateProgress(); // Initial call to set the progress
    this.progressInterval = setInterval(() => {
      this.updateProgress();
    }, 60000); // Update progress every minute
  }
  ngOnDestroy(): void {
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
    }
  }
}
