import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BaseViewItem } from '@shared/classes/view/BaseViewItem';
@Component({
  selector: 'app-html-text-view',
  templateUrl: './html-text-view.component.html',
  styleUrls: ['./html-text-view.component.scss'],
})
export class HtmlTextViewComponent extends BaseViewItem implements OnInit {
  @Input() filter: string;
  sanitizedData: SafeHtml | null = null;

  constructor(private sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit(): void { }

  onSetData() {
    this.setData(this.data);
  }

  setData(rawHtml: string) {
    this.sanitizedData = this.sanitizer.bypassSecurityTrustHtml(rawHtml ?? '');
  }
}
